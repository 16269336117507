<template>
	<div class="app-container">
		<el-button type="primary" icon="el-icon-plus" class="fr" size="small" style="margin: 0px 0 10px" @click="showAddRoleDialogFunc"
			>新增角色</el-button
		>
		<el-table border fit :data="tableData" v-loading="loading" class="tableBackground">
			<el-table-column type="index" width="65" align="center" :label="$t('roleManage.index')"></el-table-column>
			<el-table-column :label="$t('roleManage.rolename')" align="center" prop="roleName" width="150"></el-table-column>
			<el-table-column align="center" :label="$t('roleManage.description')" prop="description"></el-table-column>
			<el-table-column :label="$t('roleManage.createtime')" align="center" prop="createTime"></el-table-column>
			<el-table-column :label="$t('roleManage.updateTime')" align="center" prop="updateTime"></el-table-column>
			<el-table-column align="center" :label="$t('roleManage.operate')">
				<template slot-scope="scope">
					<!-- <el-button
            type="text"
            size="small"
            @click="showEditPermissionDialogFunc(scope.row.roleId)"
          >权限</el-button> -->
					<el-button v-show="scope.row.roleName != '超级管理员'" type="text" size="small" @click="showAddRoleDialogFunc(scope.row)"
						>修改角色</el-button
					>
					<el-button v-show="scope.row.roleName != '超级管理员'" type="text" size="small" @click="showChangeDialogFunc(scope.row)"
						>修改范围</el-button
					>
					<el-button
						v-show="scope.row.roleName != '超级管理员'"
						style="color: red"
						type="text"
						size="small"
						@click="Delete(scope.row.roleId)"
						>删除角色</el-button
					>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			class="fr"
			style="margin: 20px 0"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="table.pageNo"
			:page-sizes="[20, 50, 100, 200, 300, 400]"
			:page-size="table.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="table.totalNum"
		></el-pagination>
		<el-dialog :visible.sync="roleEditDialog.show" :title="$t('roleManage.roledigtitle')" width="35%">
			<add-role-comp v-model="roleForm"></add-role-comp>
			<div slot="footer" class="dialog-footer">
				<el-button @click="roleEditDialog.show = false">{{ $t('roleManage.no') }}</el-button>
				<el-button type="primary" :loading="addLoading" @click="onSubmitAddRoleClickedFunc">{{ $t('roleManage.yes') }}</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="permissionEditDialog.show" :title="$t('roleManage.permissiondigtitle')" width="75%">
			<edit-role-permission-comp ref="permisson" :roleId="permissionForm.roleId" v-on:checkedList="checkedList"></edit-role-permission-comp>
			<div slot="footer" class="dialog-footer">
				<el-button @click="permissionEditDialog.show = false">{{ $t('roleManage.no') }}</el-button>
				<el-button type="primary" :loading="addLoading" @click="onSubmitPermissionClickedFunc">{{ $t('roleManage.yes') }}</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="showChangeDialog" title="修改范围" width="30%">
			<el-tree
				:data="orgTree"
				node-key="id"
				:props="{ label: 'orgName' }"
				check-strictly
				ref="orgTree"
				show-checkbox
				default-expand-all
				@check="treeCheck"
			>
			</el-tree>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleClose">{{ $t('roleManage.no') }}</el-button>
				<el-button type="primary" :loading="changeLoading" @click="submitOrg">{{ $t('roleManage.yes') }}</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import local from '../local';
import * as api from '@/api/system';
import AddRoleComp from './AddRoleComp';
import EditRolePermissionComp from './EditRolePermissionComp';
import { queryDepartment } from '@/api/workbench';
const viewName = 'role';

export default {
	props: { pageName: {} },
	components: {
		EditRolePermissionComp,
		AddRoleComp
	},
	computed: {
		orgTree() {
			return this.$store.state.setData.depsList;
		}
	},
	data() {
		return {
			loading: false,
			roleForm: {},
			tableData: [],
			permissionEditDialog: {
				show: false
			},
			roleEditDialog: {
				show: false
			},
			addLoading: false,
			permissionForm: {
				roleId: '',
				subList: []
			},
			disabled: false,
			dialogFormVisible: false,
			table: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			showChangeDialog: false,
			checkedKeys: [],
			rowData: {},
			changeLoading: false
		};
	},
	methods: {
		getrolelist() {
			this.loading = true;
			const params = {
				request: {
					pageNo: this.table.pageNo,
					pageSize: this.table.pageSize,
					configSwitch: this.pageName === 'OrganizationPermissionSettings' ? 'no' : 'yes'
				}
			};

			api.getRolelist(params).then((res) => {
				if (res.list) {
					this.table.totalNum = res.totalNum;
					this.tableData = res.list;
				}
				this.loading = false;
			});
		},
		handleSizeChange(val) {
			this.table.pageSize = val;
			this.getrolelist();
		},
		handleCurrentChange(val) {
			this.table.pageNo = val;
			this.getrolelist();
		},
		getCheckKeys(id) {
			this.$axios.post('/v1/user/findOrgPermissionListByRoleId', { request: { roleId: id } }).then((res) => {
				if (res.code) {
					return;
				}
				this.checkedKeys = res;
				this.$refs?.orgTree.setCheckedKeys(res);
			});
		},
		showChangeDialogFunc(row) {
			this.rowData = row;
			this.showChangeDialog = true;
			this.getCheckKeys(row.roleId);
		},
		handleClose() {
			this.showChangeDialog = false;
			this.rowData = {};
			this.checkedKeys = [];
			this.$refs?.orgTree.setCheckedKeys([]);
		},
		treeCheck(row, { checkedKeys }) {
			this.checkedKeys = checkedKeys;
		},
		// 修改范围
		submitOrg() {
			this.changeLoading = true;
			this.$axios.post('/v1/user/saveOrgPermission', { request: { roleId: this.rowData.roleId, orgIds: this.checkedKeys } }).then((res) => {
				this.changeLoading = false;
				if (res.code) {
					return;
				}
				this.$message.success('提交成功');
				this.handleClose();
			});
		},
		showAddRoleDialogFunc(row) {
			// this.roleEditDialog.show = true;
			this.roleForm = {
				id: '',
				roleName: '',
				description: ''
			};
			this.$router.push({
				path: '/roleInfo',
				query: {
					roleId: row ? row.roleId : '',
					configSwitch: row?.configSwitch || ''
					// roleName:row ? row.roleName : '',
					// description: row? row.description : ''
				}
			});
		},
		showEditDialogFunc(row) {
			this.roleForm = row;
			this.roleEditDialog.show = true;
		},
		showEditPermissionDialogFunc(roleId) {
			this.permissionForm.roleId = roleId;
			// if (!row) return;
			// this.permissionForm = {
			//     roleId: row.id,
			//     subList: [],
			// }
			this.permissionEditDialog.show = true;
		},
		onSubmitAddRoleClickedFunc() {
			this.addLoading = true;
			const params = {
				request: {
					roleName: this.roleForm.roleName,
					description: this.roleForm.description
				}
			};

			if (this.roleForm.roleId > 0) {
				params.request.roleId = this.roleForm.roleId;
				api.updateRole(params).then((res) => {
					if (res.code == 0) {
						this.$message.success('Successful operation!');
						this.roleEditDialog.show = false;
						this.handleCurrentChange(this.table.pageNo);
					}
					this.addLoading = false;
				});
			} else {
				api.addRole(params)
					.then((res) => {
						if (res.code == 0) {
							this.$message.success('Successful operation!');
							this.roleEditDialog.show = false;
							this.handleCurrentChange(this.table.pageNo);
						}
						this.addLoading = false;
					})
					.catch(() => {
						this.addLoading = false;
					});
			}
		},
		checkedList(checkedList) {
			this.permissionForm.subList = checkedList;
		},
		onSubmitPermissionClickedFunc() {
			this.addLoading = true;
			const params = {
				request: {
					roleId: this.permissionForm.roleId,
					permissionId: this.permissionForm.subList
				}
			};

			api.editRolepermission(params)
				.then((res) => {
					if (res.code == 0) {
						this.$message.success('Successful Operation');
						this.permissionEditDialog.show = false;
					}
					this.addLoading = false;
				})
				.catch(() => {
					this.addLoading = false;
				});
		},
		Delete(roleId) {
			this.$confirm('是否确认删除该角色?', '提示', {
				confirmButtonText: '是的',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					api.deleteRole({ request: { roleId } }).then((res) => {
						if (res.code == 0) {
							this.$message.success('删除成功!');
							this.handleCurrentChange(this.table.pageNo);
						}
					});
				})
				.catch(() => {});
		}
	},
	created() {
		if (!this.$i18n.getLocaleMessage('en')[viewName]) {
			this.$i18n.mergeLocaleMessage('en', local.en);
			this.$i18n.mergeLocaleMessage('zh', local.zh);
		}
	},
	mounted() {
		this.getrolelist();
		if (!this.orgTree?.length) {
			queryDepartment({
				request: {}
			})
				.then((res) => {
					this.$store.commit('SET_DEPT', res);
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped></style>
