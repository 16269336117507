<template>
	<div class="assessBox">
		<div class="app-container" style="margin: 0">
			<div class="titlebox" v-if="!isStaff">
				<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>

				<div class="title">{{ title }}</div>
			</div>
			<div class="viewbox" :style="`${isStaff && 'margin: 0;padding:0;'}`">
				<div>
					<div style="width: 100%" class="promotionInfo">
						<div style="text-align: right">
							<el-button
								type="primary"
								icon="el-icon-edit"
								style="cursor: pointer"
								size="mini"
								@click="isEdit = true"
								v-if="isStaff && !isEdit"
								>编辑</el-button
							>
							<el-button style="cursor: pointer" icon="el-icon-close" size="mini" @click="isEdit = false" v-if="isStaff && isEdit"
								>取消</el-button
							>
							<el-button type="primary" style="cursor: pointer" icon="el-icon-check" size="mini" @click="save" v-if="isStaff && isEdit"
								>保存</el-button
							>
						</div>
						<div class="inputBox" v-if="!isStaff">
							<div>
								<label class="left_label">名称</label>
								<label class="right_label">
									<el-input v-model="roleInfo.roleName" style="width: 70%" placeholder="请输入角色名称"></el-input>
								</label>
							</div>
							<div>
								<label class="left_label" style="margin-right: 45px">描述</label>
								<label class="right_label">
									<el-input v-model="roleInfo.description" style="width: 70%" placeholder="请输入角色描述"></el-input>
								</label>
							</div>
						</div>
						<div>
							<div :style="`width: 100%; padding: 0px ${isStaff ? 0 : 35}px`">
								<!-- <label class="left_label">权限</label> -->
								<!-- <edit-role-permission-comp :roleId="roleId" v-on:checkedList="checkedList"></edit-role-permission-comp> -->
								<el-row :style="`margin: ${isStaff ? 0 : 20}px`">
									<el-col :span="24" v-for="(item, inx) in treeDataList" :key="inx">
										<div>
											<!-- <div style="margin-bottom: 20px">{{ item.title }}</div> -->
											<el-row style="background: rgb(240, 248, 255); padding: 10px; margin: 10px 0">
												<el-col :span="12">菜单名称</el-col>
												<el-col :span="12" style="text-align: right">是否有页面的操作权限</el-col>
											</el-row>
											<el-row style="margin: 10px 0">
												<el-col :span="6" style="text-align: left">
													<el-input placeholder="输入关键字进行过滤" size="mini" v-model="filterText"> </el-input>
												</el-col>
												<el-col :span="18" style="text-align: right">
													<div style="text-align: right">
														<el-checkbox
															v-model="checkAll"
															style="margin-right: 10px"
															@change="switchCheckAll"
															:disabled="!checkListData?.adminMenuIds?.length || !isEdit"
															>全部打开</el-checkbox
														>
													</div>
												</el-col>
											</el-row>

											<el-tree
												:data="item.treeData"
												:show-checkbox="isEdit"
												node-key="id"
												:default-checked-keys="checkListData[item.dataKey]"
												:props="item.props || {}"
												@check="item.check"
												:render-content="renderContent"
												:filter-node-method="filterNode"
												ref="filterTree"
											>
											</el-tree>
										</div>
									</el-col>
								</el-row>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="viewFoot" v-if="!isStaff">
				<el-button size="medium" @click="$router.go(-1)">取消</el-button>
				<el-button type="primary" size="medium" @click="save">保存</el-button>
			</div>
		</div>
	</div>
</template>
<script>
import * as api from '@/api/system';
import { transfromTreeData } from '../../../utils/util';
import { Loading } from 'element-ui';
// import EditRolePermissionComp from './EditRolePermissionComp';
export default {
	props: { pageName: {} },
	components: {
		// EditRolePermissionComp
	},
	watch: {
		filterText(val) {
			this.$refs.filterTree?.[0]?.filter(val);
		}
	},
	data() {
		return {
			filterText: '',
			roleInfo: {
				roleName: '',
				description: ''
				// permissionId: []
			},
			roleId: '',
			title: '',
			treeDataList: [],
			checkListData: {},
			roleMenuList: [],
			switchCheckData: {},
			checkAll: false,
			isEdit: false
		};
	},
	computed: {
		allTreeList() {
			return transfromTreeData(this.treeDataList[0]?.treeData);
		},
		showSwitch() {
			return (data) => {
				const { adminMenuIds = [], staffMenuIds = [] } = this.checkListData;
				const allChecks = [...adminMenuIds, ...staffMenuIds];
				const show = allChecks.includes(data.id) && !data.children?.length;

				return show;
			};
		},
		isStaff() {
			return this.pageName === 'OrganizationPermissionSettings';
		}
	},
	created() {},

	mounted() {
		this.$nextTick(() => {
			let isEdit = true;

			this.roleId = this.$route.query.roleId;
			if (!this.roleId) {
				this.title = '添加角色';
			} else {
				this.title = '修改角色';
				api.getInfoByroleId({ request: { roleId: this.roleId } })
					.then((res) => {
						this.roleInfo.roleName = res.roleName;
						this.roleInfo.description = res.description;
					})
					.catch(() => {});
				this.getDetailInfo();
			}
			this.getTreeData();
			if (this.isStaff) {
				this.getDetailInfo();
				isEdit = false;
			}
			this.isEdit = isEdit;
			// this.roleInfo.roleName = this.$route.query.roleName;
			// this.roleInfo.description = this.$route.query.description;
		});
	},
	methods: {
		filterNode(value, data) {
			if (!value) {
				return true;
			}
			return data.menuName.indexOf(value) !== -1;
		},
		openAll() {
			const { adminMenuIds = [] } = this.checkListData;

			if (adminMenuIds?.length) {
				adminMenuIds.forEach((key) => {
					const row = this.allTreeList.find(({ id }) => id === key);

					if (['C', 'F'].includes(row.menuType)) {
						this.$set(this.switchCheckData, row.id, true);
					}
				});
			}
		},
		switchCheckAll(e) {
			if (e) {
				this.openAll();
			} else {
				Object.keys(this.switchCheckData).forEach((id) => {
					this.$set(this.switchCheckData, id, false);
					// this.$delete(this.switchCheckData, id);
				});
			}
		},
		renderContent(h, { node, data }) {
			const isNotShow = data.isShow === 0;

			let color = '#333';

			// switch (data.menuType) {
			// 	case 'M':
			// 		color = '#333';
			// 		break;
			// 	case 'C':
			// 		color = '#4a8df0';
			// 		// color = '#5e16ff';
			// 		break;
			// 	case 'F':
			// 		color = '#81cbff';
			// 		break;

			// 	default:
			// 		break;
			// }
			if (isNotShow) {
				color = '#00000047';
			}
			// 默认勾选
			return (
				<span class="custom-tree-node">
					<span style={`color:${color}`} title={isNotShow && '不显示在菜单或标签的页面'}>
						{node.label}
					</span>
					<span>
						{this.showSwitch(data) && (
							<el-switch
								disabled={!this.isEdit}
								v-model={this.switchCheckData[data.id]}
								active-color="#13ce66"
								inactive-color="#ff4949"
							></el-switch>
						)}
					</span>
				</span>
			);
		},
		getDetailInfo() {
			const { configSwitch, roleId } = this.$route.query;

			this.$axios.post('/v1/menu/findMenuIdsbyRoleId', { request: { configSwitch, roleId } }).then((res) => {
				if (res?.code) {
					return;
				}
				const checkIds = res.map(({ id }) => id);

				this.$set(this.checkListData, 'adminMenuIds', checkIds);
				res.forEach(({ id, isOperation }) => {
					this.$set(this.switchCheckData, id, Boolean(isOperation));
				});
			});
		},
		getTreeData() {
			const loading = Loading.service({ target: '.promotionInfo' });

			this.$axios
				.post('/v1/menu/listAllByComId', { request: { configSwitch: this.$route.query.configSwitch || (this.isStaff ? 'no' : 'yes') } })
				.then((res) => {
					loading.close();
					if (res.code) {
						return;
					}
					this.treeDataList = [
						{
							// title: '管理菜单配置',
							treeData: res,
							props: { label: 'menuName' },
							dataKey: 'adminMenuIds',
							check: (e, { checkedKeys }) => {
								this.$set(this.checkListData, 'adminMenuIds', checkedKeys);
								if (!checkedKeys.length) {
									this.checkAll = false;
								}
							}
						}
						// {
						// 	title: '个人菜单配置',
						// 	treeData: res.staffMenuList,
						// 	props: { label: 'menuName' },
						// 	dataKey: 'staffMenuIds',
						// 	check: (e, { checkedKeys }) => {
						// 		this.$set(this.checkListData, 'staffMenuIds', checkedKeys);
						// 		if (checkedKeys?.length) {
						// 			checkedKeys.forEach((id) => {
						// 				// this.switchCheckData[id] = true;
						// 				this.$set(this.switchCheckData, id, true);
						// 			});
						// 		}
						// 	}
						// }
					];
				});
		},
		checkedList(checkedList) {
			this.roleInfo.permissionId = checkedList;
		},
		getAllParentIds(ids, list) {
			let allId = [];

			ids.forEach((i) => {
				const item = list.find(({ id }) => id === i);

				if (item?.parentId && item?.parentId !== '0') {
					allId = allId.concat([item.parentId, ...this.getAllParentIds([item.parentId], list)]);
				}
			});
			return allId;
		},
		save() {
			if (this.roleInfo.roleName == '' && !this.isStaff) {
				this.$message.error('角色名称不能为空');
				return;
			}
			if (this.roleInfo.description == '' && !this.isStaff) {
				this.$message.error('描述不能为空');
				return;
			}
			const { adminMenuIds = [] } = this.checkListData;

			const halfIds = this.getAllParentIds(adminMenuIds, this.allTreeList);

			const adminChecks = Array.from(new Set([...adminMenuIds, ...halfIds])).map((id) => ({
				menuId: id,
				isOperation: this.switchCheckData[id] ? 1 : 0
			}));

			if (this.isStaff) {
				const params = {
					request: { roleMenuList: adminChecks }
				};

				api.updateRole(params).then((res) => {
					if (res.code == 0) {
						this.$message.success('修改成功');
						this.isEdit = false;
					}
				});
				return;
			}
			if (this.roleId == undefined) {
				api.addRole({ request: { ...this.roleInfo, roleMenuList: adminChecks } })
					.then((res) => {
						if (res.code == 0) {
							this.$message.success('角色创建成功');
							this.$router.go(-1);
						}
					})
					.catch(() => {});
			} else {
				api.updateRole({
					request: {
						roleId: this.roleId,
						roleName: this.roleInfo.roleName,
						description: this.roleInfo.description,
						roleMenuList: adminChecks
						// permissionId: this.roleInfo.permissionId
					}
				}).then((res) => {
					if (res.code == 0) {
						this.$message.success('角色修改成功');
						this.$router.go(-1);
					}
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.assessBox .app-container .viewbox .level_box_now {
	font-weight: bold;
}
.picbox {
	padding: 0px 0 0 100px;
	background: #fff;
	margin: 0 20px;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	height: 140px;
	:first-child {
		margin-right: 10px;
	}

	h2 {
		margin: 10px 0 0 0;
	}

	p {
		margin: 15px 0px;

		color: #999999;
	}
}

.assessBox {
	.app-container {
		color: #484848;
		padding: 0;
		background-color: #f6f7fb;
		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
			text-align: center;
			line-height: 17px;
			font: bold 18px/25px 'Microsoft YaHei';
			margin: 20px;
		}
		.viewbox {
			margin: 0 20px;
			background: #ffffff;
			min-height: 300px;
			padding: 50px;
			min-width: 900px;
			.promotionInfo {
				text-align: center;
				font-size: 14px;
				min-height: 400px;
				.inputBox {
					margin-bottom: 10px;
					div {
						width: calc(100% / 2);
						display: inline-block;
					}
				}
				.left_label {
					margin-right: 30px;
					color: #999999;
					text-align: right;
					width: 130px;
					font-weight: normal;
				}
				.right_label {
					width: 70%;
				}
				:deep(.custom-tree-node) {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 14px;
					padding-right: 8px;
				}
			}
		}

		.viewFoot {
			text-align: center;
			.el-button {
				margin: 15px;
				padding: 10px 45px;
			}
		}
	}
}
.titleIcon {
	display: inline-block;

	width: 8px !important;

	height: 20px;

	background: #498df0;

	border-radius: 5px;

	vertical-align: bottom;

	margin-right: 5px !important;
}
</style>
<style lang="scss">
.promotion {
	.el-input {
		width: 100% !important;
	}
}
.jobSelect .el-input--suffix {
	width: 100% !important;
}
</style>
