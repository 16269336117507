<template>
  <section
    class="role-permission-comp"
    v-loading="dataLoading"
  >
    <el-form label-width="110px">
      <el-form-item label="全选" style="text-align: left;">
        <el-checkbox  v-model="checkAll" @change="onCheckedAllChange"></el-checkbox>
      </el-form-item>
      <el-checkbox-group v-model="checkedList" @change="onCheckedListChange">
        <template v-for="item in ALL_LIST">
          <el-form-item :label="item.moduleName" :key="item.moduleName">
            <el-row>
              <template v-if="item.list && item.list.length>0">
                <el-col style="text-align:left" :span="6" v-for="pitem in item.list" :key="pitem.permissionId">
                  <el-checkbox :label="pitem.permissionId">{{pitem.permissionName}}</el-checkbox>
                </el-col>
              </template>
            </el-row>
          </el-form-item>
        </template>
      </el-checkbox-group>
    </el-form>
  </section>
</template>

<script>
import * as api from '@/api/system';

export default {
  name: 'edit-role-permission-comp',
  props: {
    roleId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      allList: [],
      checkedList: [],
      checkAll: false,
      dataLoading: false,
      isIndeterminate: false,
      permissionSum: 0,
      ALL_LIST: []
    };
  },
  mounted() {
    api.getAllPermission({request: {}}).then((res) => {
        this.ALL_LIST = res.permissionList;
        this.permissionSum = res.permissionSum;
    });
    this.getRolePrivilegeFunc();
  },
  watch: {
    roleId() {
      this.getRolePrivilegeFunc();
    }
  },
  methods: {
    getRolePrivilegeFunc() {
      if (this.roleId) {
        this.dataLoading = true;
        this.checkedList = [];
        this.checkAll = false;
        api.getRolepermission({request: {roleId: this.roleId}}).then((res) => {
          this.checkedList = res;
          this.$emit('checkedList', this.checkedList);
          this.dataLoading = false;
      });
      }
    },
    onCheckedAllChange(value) {
      this.checkedList = [];
      if (value) {
        this.ALL_LIST &&
          this.ALL_LIST.forEach((it) => {
            it.list.forEach((pit) => {
              this.checkedList.push(pit.permissionId);
            });
          });
      }
      this.isIndeterminate = false;
      this.$emit('checkedList', this.checkedList);
    },

    onCheckedListChange(value) {
      this.$emit('checkedList', value);
      const checkedCount = value.length;

      this.checkAll = checkedCount == this.permissionSum;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.permissionSum;
    }
  }
};
</script>

<style lang="scss">
</style>
