<template>
    <section>
        <el-form label-width="100px">
            <el-form-item label="名称">
                 <el-input v-model="value.roleName" style="width:70%" placeholder="请输入角色名称"></el-input>
            </el-form-item>
            <el-form-item label="描述">
                <el-input v-model="value.description" style="width:70%" placeholder="请输入角色描述"></el-input>
            </el-form-item>
        </el-form>

    </section>
</template>

<script>

    export default {
        name: 'add-role-comp',
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            };
        }
    };
</script>

<style scoped lang="scss">

</style>
