<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="管理员设置" name="1" v-if="permissionControl('MenuPermissionSetting')">
				<RightsProfile pageName="MenuPermissionSetting" v-if="activeName === '1'" />
			</el-tab-pane>
			<el-tab-pane label="个人设置" name="2" v-if="permissionControl('OrganizationPermissionSettings')">
				<RoleInfo pageName="OrganizationPermissionSettings" v-if="activeName === '2'" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import RightsProfile from '@/views/system-config/rights-profile/role';
import RoleInfo from './rights-profile/roleInfo.vue';
// import OrgPrinList from '@/views/system-config/orgPrinConfig/orgPrinList';

export default {
	components: {
		RightsProfile,
		RoleInfo
		// OrgPrinList
	},
	/**  mounted*/
	mounted() {
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'MenuPermissionSetting' },
			{ name: '2', key: 'OrganizationPermissionSettings' }
		]);
	},
	/**  data*/
	data() {
		return {
			activeName: '1'
		};
	},
	methods: {}
};
</script>

<style></style>
